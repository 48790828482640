import "./App.css";
import Landing from "./Landing";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#800080",
      next: "#000000",
    },
    secondary: {
      main: "#ede6ed",
      next: "#333333",
    },
    body: {
      secondary: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", height: "100vh", width: "100%" }}>
        <Landing />
      </Box>
    </ThemeProvider>
  );
}

export default App;
