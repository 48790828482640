import { Box, Typography } from "@mui/material";
import { StyledCard, StyledCardData, StyledCardTitle } from "./style";
import { PregnantWoman } from "@mui/icons-material";
import {
  MdCardMembership,
  MdPregnantWoman,
  MdWebStories,
  MdWoman,
} from "react-icons/md";
import { FaBaby } from "react-icons/fa";
import { BsPersonVcard } from "react-icons/bs";

const Services = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "secondary.main",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1em 0em",
        paddingBottom: 7,
      }}
    >
      <Typography variant="h3" sx={{ m: "1em 0em" }}>
        Our Services
      </Typography>

      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", md: "75%" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <StyledCard>
          <BsPersonVcard size={100} style={{ color: "#800080" }} />

          <StyledCardTitle>
            Center of Specialties (Comprehensive care)
          </StyledCardTitle>
          <StyledCardData>
            Provision of integrated care to enhance your physical, mental and
            social health.
          </StyledCardData>
        </StyledCard>

        <StyledCard>
          <MdWebStories size={100} style={{ color: "#800080" }} />
          <StyledCardTitle>
            Telemedicine and House-call (Convenient care)
          </StyledCardTitle>
          <StyledCardData>
            Optimizes technology to spread the knowledge of experts, implement
            standard quality health services and overall enhancement of the
            human experience.
          </StyledCardData>
        </StyledCard>

        <StyledCard>
          <MdCardMembership size={100} style={{ color: "#800080" }} />
          <StyledCardTitle>Meklit Membership</StyledCardTitle>
          <StyledCardData>
            Comprehensive and convenient care tailored to your needs throughout
            your lifetime
          </StyledCardData>
        </StyledCard>
      </Box>
    </Box>
  );
};

export default Services;
