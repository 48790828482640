import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Logo from "../assets/logo.png";
import { Link } from "@mui/material";
import { EVENT_REGISTRATION_PAGE, VIDEO_PAGE } from "../constants/url";
// import { useNavigate } from "react-router-dom";
// import { authPages } from "routes/menu";

const drawerWidth = 240;
// const navItems = [{name:'Appointments',link:'portal.meklit.life/'}]

export default function DrawerAppBar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={Logo}
          style={{
            width: 50,
            height: 50,
            marginLeft: "0.7em",
            marginRight: "0.7em",
          }}
        />
        <Typography variant="h6" sx={{ my: 2 }}>
          Meklit
        </Typography>
      </Box>

      <Divider />
      {/* <List> */}
      {/* {navItems.map((item) => ( */}
      {/* <ListItem key={item} disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText primary="appointments" />
          </ListItemButton>
        </ListItem> */}
      {/* ))} */}

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Link href="/video">
          <Button
            sx={{
              textTransform: "initial",
              borderRadius: 15,
              fontSize: 20,
              color: "secondary.next",
              fontFamily: "inter",
              fontWeight: "200",
            }}
          >
            Video
          </Button>
        </Link>

        <Link href="https://forum.meklit.life">
          <Button
            sx={{
              textTransform: "initial",
              borderRadius: 15,
              fontSize: 20,
              color: "secondary.next",
              fontFamily: "inter",
              fontWeight: "200",
            }}
          >
            Community
          </Button>
        </Link>
        <Link href="portal.meklit.life">
          <Button
            sx={{
              textTransform: "initial",
              borderRadius: 15,
              fontSize: 20,
              color: "secondary.next",
              fontFamily: "inter",
              fontWeight: "200",
            }}
          >
            Sign In
          </Button>
        </Link>
      </Box>

      {/* </List> */}
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: (theme) => theme.palette.body.secondary,
          color: (theme) => theme.palette.secondary.next,
          display: "flex",
          boxShadow: "none",
          padding: { xs: "1em 2em", md: "1em 5em" },
          justifyContent: { xs: "space-between", md: "unset" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "space-between", md: "unset" },
            }}
          >
            <img src={Logo} style={{ width: 60 }} />
            <Typography
              variant="h4"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }, ml: 2 }}
            >
              Meklit
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" }, ml: "auto" }}>
            <Link href={VIDEO_PAGE}>
              <Button
                sx={{
                  textTransform: "initial",
                  borderRadius: 15,
                  fontSize: 20,
                  color: "secondary.next",
                  mr: 5,
                  fontWeight: "400",
                  fontFamily: "inter",
                }}
              >
                Video
              </Button>
            </Link>
            <Link href="https://forum.meklit.life">
              <Button
                sx={{
                  textTransform: "initial",
                  borderRadius: 15,
                  fontSize: 20,
                  color: "secondary.next",
                  mr: 5,
                  fontWeight: "400",
                  fontFamily: "inter",
                }}
              >
                Community
              </Button>
            </Link>
            <Link href={EVENT_REGISTRATION_PAGE}>
              <Button
                sx={{
                  textTransform: "initial",
                  borderRadius: 15,
                  fontSize: 20,
                  color: "secondary.next",
                  mr: 5,
                  fontWeight: "400",
                  fontFamily: "inter",
                }}
              >
                Event
              </Button>
            </Link>
            <Link href="https://portal.meklit.life/login">
              <Button
                sx={{
                  textTransform: "initial",
                  borderRadius: 15,
                  fontSize: 20,
                }}
                variant="contained"
              >
                Sign In
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
