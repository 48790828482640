import { Box, Button, Card, Typography } from "@mui/material";
import { styled } from "@mui/system";
// import { styled } from "@mui/material/styles";

export const StyledCard = styled(Box)(({ theme }) => ({
  margin: "3em 2em",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "inter",
  fontWeight: "700",
  marginBottom: "0.5em",
  color: theme.palette.primary.main,
  marginTop: "1em",
  textAlign: "center",
}));

export const StyledCardData = styled(Typography)(({ theme }) => ({
  fontFamily: "inter",
  fontWeight: "300",
  marginLeft: "0.5em",
  textAlign: "center",
  width: "80%",
}));

export const StyledContact = styled(Typography)(() => ({
  fontFamily: "inter",
  fontWeight: "300",
  fontSize: "20",
  marginBottom: "0.5em",
}));
