import { Box, Typography, Button } from "@mui/material";
import DrawerAppBar from "./NavBar";
import Services from "./Services";
import About from "./About";
import Contacts from "./Contacts";
import mother from "../assets/mother.png";
import { Link } from "@mui/material";

const Landing = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mb: 10 }}>
        <DrawerAppBar />
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: { xs: "7em 2em", md: "7em 10em" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", lg: "70%" },
            display: "flex",
            flexDirection: "column",
            mr: { xs: 0, lg: 10 },
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              fontFamily: "inter",
              fontWeight: "700",
              fontSize: { xs: 40, md: 75 },
            }}
          >
            Fulfilling humankind's potential
          </Typography>
          <Typography
            sx={{
              fontFamily: "inter",
              fontWeight: "300",
              fontSize: { xs: 20, md: 30 },
              mt: 2,
              mb: 4,
            }}
          >
            Book your appointment now!
          </Typography>
          <Link href="https://portal.meklit.life/login">
            <Button
              sx={{
                textTransform: "initial",
                borderRadius: 15,
                fontSize: 20,
                width: { xs: "100%", md: "40%" },
              }}
              variant="contained"
            >
              Sign In
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            width: "30%",
          }}
        >
          <img src={mother} style={{ width: "100%" }} />
        </Box>
      </Box>

      <Services />
      <About />
      <Box
        sx={{
          display: "flex",
          p: { xs: " 5em 2em", md: " 5em 10em" },
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <img src={mother} style={{ width: "25%" }} />
        <Contacts />
      </Box>
      <Box sx={{ pb: 3 }}>
        {" "}
        <Typography
          sx={{
            fontFamily: "inter",
            fontWeight: "200",
            fontSize: 20,
            color: "secondary.next",
            textAlign: "center",
          }}
        >
          &copy; {new Date().getFullYear()} Meklit. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Landing;
