import { Box, Typography } from "@mui/material";

const About = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        backgroundColor: "primary.main",
        width: "100%",
        color: "body.secondary",
        padding: { xs: "5em 2em", md: "5em 10em" },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "25%" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontFamily: "inter", fontWeight: "700", mb: 2 }}
          variant="h2"
        >
          Few Words About Us
        </Typography>
      </Box>
      <Box sx={{ width: { xs: "100%", md: "75%" } }}>
        <Typography
          sx={{
            fontFamily: "inter",
            fontWeight: "300",
            fontSize: 22,
            mr: { xs: 2, md: 5 },
            textJustify: "auto",
          }}
        >
          Meklit is the care delivery system of Mulu-G in which it delivers a
          comprehensive, convenient, customized, and continuous care (4C care)
          for the enhancement of the physical, mental, and social well-being of
          an individual towards the goal of fulfillment of his/her potential.
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
