import { Box, Typography } from "@mui/material";
import { BsTelephone, BsTelephoneFill } from "react-icons/bs";
import { MdEmail, MdOutlineEmail } from "react-icons/md";
import { StyledContact } from "./style";
import { HiLocationMarker } from "react-icons/hi";

const Contacts = () => {
  return (
    <Box sx={{ ml: 2 }}>
      <Typography
        sx={{
          fontFamily: "inter",
          fontWeight: "600",
          fontSize: 40,
          color: "primary.main",
          mb: 2,
          mt: { xs: 3, md: 0 },
        }}
      >
        Contact Us
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <BsTelephoneFill size={25} style={{ color: "#800080" }} />
        <Box>
          <StyledContact sx={{ ml: 3 }}> +2519-2610-5619</StyledContact>

          <StyledContact sx={{ ml: 3 }}> +2519-2610-5662</StyledContact>

          <StyledContact sx={{ ml: 3 }}> +2519-0256-7371</StyledContact>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <MdEmail size={25} style={{ color: "#800080" }} />
        <StyledContact sx={{ ml: 3 }}> info@meklit.life </StyledContact>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <HiLocationMarker size={25} style={{ color: "#800080" }} />
        <StyledContact sx={{ ml: 3 }}>
          {" "}
          Addis Ababa, Ethiopia. Bole infront of Ethiopian Airlines cargo
          terminal{" "}
        </StyledContact>
      </Box>
    </Box>
  );
};

export default Contacts;
